<template>
  <div :class="`m-3 p-2 wrapper  wrapper--${bu}`">
    <h2 class="text-center">Halen - {{ bu }}</h2>
    <Loading v-if="loading" />
    <Tabs v-if="!loading">
      <Tab v-for="(buyer, key) in pickup_orders" :key="key" :title="key">
        <table :class="`w-100 table--default table__border--${bu}`">
          <thead :class="`table__head--${bu}`">
            <tr>
              <th class="table__head--text">Status</th>
              <th class="table__head--text">Item</th>
              <th class="table__head--text">Omschrijving</th>
              <th class="table__head--text" style="min-width: 100px">Datum</th>
              <th class="table__head--text">Betaald</th>
              <th
                v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                class="table__head--text"
              >
                Leverancier
              </th>
              <th
                v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                class="table__head--text"
              >
                Locatie
              </th>
              <th class="table__head--text">Transporteur</th>
              <th class="table__data--right table__head--text">Kosten</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in buyer"
              :key="key"
              class="table__row--color"
            >
              <td class="table__cell--default">
                <div v-if="item.statusnaam == 'nieuw'">
                  <span
                    class="dot dot--red"
                    v-b-tooltip.hover
                    title="Nieuw"
                  ></span>
                </div>
                <div v-else>
                  <span
                    class="dot dot--blue"
                    v-b-tooltip.hover
                    title="Ingepland"
                  ></span>
                </div>
              </td>
              <td class="table__cell--default">
                <ImageHover :itemnummer="item.itemnummer" :bu="item.bu" />
              </td>
              <td class="table__cell--default">{{ item.voertuig }}</td>
              <td class="table__cell--default">{{ item.aanleverdatum }}</td>
              <td class="table__cell--default">
                <div v-if="item.betaald == 1">
                  <span v-b-tooltip.hover :title="item.betaaldatum"
                    ><i class="fas fa-euro-sign"></i
                  ></span>
                </div>
                <div v-else>
                  <span v-b-tooltip.hover title="Nog niet betaald"
                    ><i class="fab fa-creative-commons-nc-eu"></i
                  ></span>
                </div>
              </td>
              <td
                v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                class="table__cell--default"
              >
                {{ item.bedrijf }}
              </td>
              <td
                v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                class="table__cell--default"
              >
                {{ item.locatie }}
              </td>
              <td class="table__cell--default" v-if="item.transporteur">
                {{ item.transporteur }}
              </td>
              <td class="table__cell--default" v-else></td>
              <td class="table__data--right table__cell--default">
                € {{ item.kosten }}
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import ImageHover from "@/components/ImageHover.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";

export default {
  props: ["bu"],
  components: { Loading, ImageHover, Tabs, Tab },
  data: () => ({
    pickup_orders: null,
    loading: null,
  }),
  created() {
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      this.loading = true;
      request(`pickup-orders/${bu}`, "GET").then(({ pickup_orders }) => {
        this.pickup_orders = pickup_orders;
        this.loading = false;
      });
    },
  },
};
</script>
